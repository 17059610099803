import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as _ from 'lodash';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProgramContextCollection } from '@Core/Lib/Contexts/program-context-collection';
import { ProgramChange } from '@Core/CodeGen/Models/configuration.models';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { EditableStateEnum } from '@Core/Lib/Enums/editable-state-enum';

import { UserService } from '@Services/user-service';
import { ProgramProgramsService } from '@Services/program-programs-service';


@Injectable()
export class ChangeLockGuard implements CanActivate {
    constructor(public router: Router,
        private userService: UserService,
        private programService: ProgramProgramsService,
        private programContextCollection: ProgramContextCollection) { }

    private programId: string;

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const self = this;

        this.programId = route.parent.params['key'];
        if (!this.programId) {
            console.warn("Tried to run Change Lock Guard, but the programId was null");
            return of(false);
        }
        var context = this.programContextCollection.get(this.programId);

        var changeId = route.params['changeId'];      
        if (!changeId) {
            this.redirect();
            return of(false);
        }
        context.changeId = changeId;
        context.programId = this.programId;
        context.patchId = null;
        
        return combineLatest([
            this.userService.User$,
            this.programService.getChange(changeId, context)
        ]).pipe(map(
            responses => {
                var change = context.get(ModelUtils.createDomainId(new ProgramChange(), responses[1].Id)) as ProgramChange;
                var edge = _.first(change.Edits());
                var lockedByUser = edge.LockedBy;
                if (lockedByUser == responses[0].Id) {
                    self.programService.setEditableState({ ChangeId: change.Id, EditableState: EditableStateEnum.Edit });
                    return true;
                }
                else {
                    // If the current user doesn't have the lock, re-route to Program Overview
                    self.redirect();
                    return false;
                }
            }, error => {
                self.redirect();
                return false;
            }
        ));
    }

    private redirect() {
        this.router.navigate(['Conf', 'Programs', this.programId]);
    }
}
