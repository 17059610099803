import _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { IStaticElementType, IValidationCode } from '@Core/Lib/model';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { Program, Workflow } from '@Core/CodeGen/Models/configuration.models';
import { ProgramTeam } from '@Core/Models/ProgramTeam';

import { ProgramProgramsDataStoresService } from '@Services/program-programs-datastores-service';
import { ProgramProgramsService } from '@Services/program-programs-service';
import { FeatureFacetService } from '@Services/feature-facet.service';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';

// ProgramContext - Applies to program administration.
// A reference to the correct data context object may be obtained from an injected ProgramContextCollection.
// There's one for each Program actively being worked on.
export class ProgramContext extends DataContext {
    public isLoaded: boolean = false;
    public get program(): Program { return this.get(`Configuration#${this.programId}`) as Program };
    public programTeam: BehaviorSubject<ProgramTeam> = new BehaviorSubject<ProgramTeam>(null);
    public staticElementTypes: BehaviorSubject<IStaticElementType[]> = new BehaviorSubject<IStaticElementType[]>(null);
    public validationCodes: BehaviorSubject<IValidationCode[]> = new BehaviorSubject<IValidationCode[]>(null);

    constructor(public programId: string) {
        super();
    }

    public reload(service: ProgramProgramsService, dataStoreService: ProgramProgramsDataStoresService, 
        featureFacetService: FeatureFacetService): Observable<any> {
        let progId = this.programId;

        var requests: Observable<any>[] = [
           // Programs
           service.loadProgramByKey(progId, this),
           service.loadProgramChanges(progId, this),
           service.loadProgramRevisions(progId, this),
           service.getProgramValidationMessages(this),
           service.loadProgramPatches(this.programId, this),

           // Team
           service.loadProgramWorkGroups(this),
           service.loadProgramAccountRoles(this),

           // Data Store
           dataStoreService.loadDataStores(progId, this),
           dataStoreService.loadProgramDataGroups(progId, this),

           // Workflows
           service.loadWorkflowSets(progId, this),
           service.loadWorkflows(progId, this),
           service.loadPhases(progId, null, this),
           service.loadTasks(progId, null, this),

           // Applications
           service.loadApplications(progId, this),
           service.loadApplicationSectionsWithElements(this, null),

           // Rules
           service.loadRuleSets(progId, this),
           service.loadRules(progId, null, this), 
        ];

        var observable = forkJoin(requests);
        observable.subscribe(() => {
            this.finalizeLoad(service);

            featureFacetService.getAllFacets()
                .subscribe(featureFacets => {
                if (FeatureFacetService.checkIfFacetExists(TenantPermissions.Flag_Programs_Changes_Assets, 
                        featureFacets, 'conf')) {
                    
                    // Assets
                    service?.loadAssetGroups(progId, this);
                    requests.push(service.loadAssets(progId, null, this));
                }
            });
        });

        return observable;
    }

    public retrieveProgramConfiguration(revisionId: string, service: ProgramProgramsService, patchId: string): Observable<any> {
        var observable = forkJoin([
            service.retrieveProgramConfiguration(this.programId, revisionId, this, patchId),
            service.loadProgramRevisions(this.programId, this),
            service.loadProgramChanges(this.programId, this),
            service.loadProgramAccountRoles(this),
            service.loadProgramWorkGroups(this),
            service.loadProgramPatches(this.programId, this)
        ]);

        observable.subscribe(() => {
            this.finalizeLoad(service);
        })

        return observable;
    }

    public finalizeLoad(service: ProgramProgramsService) {
        this.isLoaded = true; // mark as loaded to prevent reloads
        service.getStaticElementTypes().subscribe(types => {
            this.staticElementTypes.next(types);
        });
        service.getValidationCodes().subscribe(codes => {
            this.validationCodes.next(codes);
        });
        this.broadcastAll();
    }

    public loadProgramRevision(revisionId: string, service: ProgramProgramsService): Observable<any> {
        var observable = forkJoin([
            service.loadProgramRevision(this.programId, revisionId, this),
            service.loadProgramRevisions(this.programId, this),
            service.loadProgramChanges(this.programId, this),
            service.loadProgramPatches(this.programId, this)
        ]);

        observable.subscribe(() => {
            this.isLoaded = true; // mark as loaded to prevent reloads
            this.broadcastAll();
        })

        return observable;
    }

    private _programTeamLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public get programTeamLoaded(): BehaviorSubject<boolean> {
        return this._programTeamLoaded;
    }
    public setProgramTeamLoaded(isLoaded: boolean): void {
        this._programTeamLoaded.next(isLoaded);
    }
    /** Ensures the ProgramTeam is loaded.
     * Callers should subscribe to the programTeam property. */
    public loadProgramTeam(service: ProgramProgramsService): void {
        if (!this._programTeamLoaded.value) {
            service.loadPublishedProgramTeam(this)
                .subscribe(() => {
                    this.setProgramTeamLoaded(true);
                });
        }
    }

    public loadWorkflowData(workflow: Workflow, service: ProgramProgramsService): void {
        // Saving a new Workflow doesn't return any relationships
        //    so we need to retrieve the full Workflow definition
        service.loadWorkflow(workflow.Id, this);
        service.loadPhases(this.programId, workflow.Id, this);
        service.loadTasks(this.programId, workflow.Id, this);
    }

    public changeId: string;
    public patchId: string;
}