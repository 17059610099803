import { Component, Input, Output, EventEmitter, HostBinding } from "@angular/core";
import _ from "lodash";
import { Observable } from "rxjs";
import { SmAssignableBadgeVM } from "../sm-assignable-badge/sm-assignable-badge.viewmodel";

@Component({
    selector: 'sm-info-stack',
    templateUrl: './sm-info-stack.component.html',
    styleUrls: ['./sm-info-stack.component.scss']
})
export class SmInfoStackComponent {

    @Input() public model: InfoStackRow[] = [];

    @Output() public linkClicked: EventEmitter<any> = new EventEmitter();
    @Output() public buttonClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.additional_column') @Input() additionalColumn: boolean = false;

    public onLinkClick(row: InfoStackRow, line: InfoLine): void {
        this.linkClicked.emit({ Label: row.Label, Id: line.Id });
    }

    public onButtonClick(row: InfoStackRow, line: InfoLine): void {
        this.buttonClicked.emit({ Label: row.Label, Id: line.Id });
    }

    public getLineClasses(line: InfoLine): string[] {
        var classes: string[] = [];

        if (line.Classes?.length) {
            classes = line.Classes;
        }

        if (line.State == 'Bold')
            classes.push('label_name_grid--name-bold');

        if (line.State == 'Italic')
            classes.push('label_name_grid--name-italic');

        return classes;
    }
};

export interface InfoStackRow {
    Label: string;
    InfoLines: InfoLine[];
    ForForm?: boolean;
    // Use to have different info stacks on the same page to have the same width;
    LabelSize?: 'sm' | 'md' | 'lg' | 'xl'; 
    AdditionalColumnText?: string;
}

export type InfoLineState = 'Normal' | 'Italic' | 'Bold' | 'Link' | 'Button' | 'GuidReplacement' | 'DateBy' | 'Phone' | 'ExternalLink'| 'Email';

export interface InfoLine {
    Id?: string; // Used when the button or link is clicked
    MainText: string;
    State: InfoLineState;
    Disabled?: boolean;
    RightText?: string;
    RightIcon?: string;
    RightIconTooltip?: string;
    DeployedBy?: Observable<SmAssignableBadgeVM>;
    DeployedOn?: Date;
    Classes?: string[];
}