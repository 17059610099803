import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';

import * as _ from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProgramContextCollection } from '@Core/Lib/Contexts/program-context-collection';
import { ProgramProgramsService } from '@Services/program-programs-service';


@Injectable()
export class ProgramExistsGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router,
    private programService: ProgramProgramsService,
    private programContextCollection: ProgramContextCollection) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(childRoute);
  }

  private programId: string;

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const self = this;

    this.programId = route.params['key'];
    if (!this.programId) {
      console.warn("ProgramId was null");
      return of(false);
    }
    var context = this.programContextCollection.get(this.programId);

    if (context !== null && context.isLoaded === true) {
      return of(true);
    }

    var response = this.programService.retrieveProgramWithResponseThrowOnError(this.programId).pipe(
      map((response) => {
        if (response && response.status == 200) {
          return true;
        } else {
          this.router.navigate(['Conf', 'Programs', 'not-found']);
          return false;
        }
      }), 
      catchError((error) => {    
        if (error.status == 404) {
          console.error('Error:', error);        
          this.router.navigate(['Conf', 'Programs', 'not-found']);
          return of(false);
        } else {
          throwError(() => error);
        }
      }));

      return response;
  }
}
