import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { DataContextCollection } from '@Core/Lib/Contexts/data-context-collection';
import { ProgramContext } from '@Core/Lib/Contexts/program-context';

// Singleton collection of ProgramContext objects, referencable by id.
@Injectable({
    providedIn: 'root',
})
export class ProgramContextCollection extends DataContextCollection<ProgramContext> {
    constructor() {
        super(ProgramContext);
    }
}