<div class="unity-avatar {{color}}"
    [ngClass]="{'disabled': model?.Disabled, 'has_image': image}"
    [ngStyle]="{'background-image': (image) ? 'url(' + image + ')' : null}"
    [ngbTooltipSticky]="userInfo"
    [placement]="['bottom','auto']">
    <svg viewBox="0 0 50 50" [ngClass]="{'icon-completing p-1': !model}">
        <use *ngIf="!model" xlink:href="#icon-completing"></use>
        <text *ngIf="model" x="50%" y="50%" font-size="25" dominant-baseline="central" text-anchor="middle" class="user_initials">{{model?.Initials}}</text>
    </svg>
</div>
<ng-template #userInfo>
    <div class="user_info">
        <h2 class="user_info--name">{{model?.Name}}<i *ngIf="model?.Disabled" class="text-muted"> (disabled)</i></h2>
        <div class="user_info--title">{{model?.Title}}</div>
        <div class="user_info--email"><a class="on_dark" href="mailto:{{model?.Email}}">{{model?.Email}}</a></div>
    </div>
</ng-template>
