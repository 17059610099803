import { Component, DestroyRef, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '@Services/auth-service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoadingService } from '@Services/loading-service';
import { DragDropService } from '@Services/drag-drop-service';
import { AlertsHub } from '@Services/Hubs/AlertsHub';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'work',
    templateUrl: './work.component.html',
    styleUrls: ['./work.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class WorkComponent {
    public loading = false;

    constructor(public authService: AuthService,
        private router: Router,
        private loadingService: LoadingService,
        private elementRef: ElementRef,
        private dragDropService: DragDropService,
        private alertsHub: AlertsHub,
        private destroyRef: DestroyRef) {
            this.authService.spaName = 'work';
            this.loadingService.loading
            .pipe(takeUntilDestroyed(destroyRef))
            .subscribe(l => {
                this.loading = l;
        });

        this.router.events
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loadingService.loading.next(true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loadingService.loading.next(false);
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngOnInit() {
        this.alertsHub.startConnection();
    }

    onDragOver(event: DragEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.dragDropService.setDragging(true);
    }

    onDragLeave(event: DragEvent): void {
        if (!event.relatedTarget || !this.elementRef.nativeElement.contains(event.relatedTarget)) {
            this.dragDropService.setDragging(false);
        }
    }
};
