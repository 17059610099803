import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { SmDateFormats } from "@Shared/Pipes/sm-date.pipe";
import _ from "lodash";
import { Subscription } from "rxjs";
import { FormWidths } from "./sm-form-helper";
import { SmElementChangedEvent, SmFormService } from "./sm-form-service";
import { FormValueChangeEvent, SmFormElementVM, SmFormState } from "./sm-form.component";

@Component({
    selector: 'sm-form-field',
    templateUrl: './sm-form-field.component.html',
    styleUrls: ['./sm-form-field.component.scss']
})
export class SmFormFieldComponent  {

    @Input() model: SmFormFieldVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    // Propagate whenever a change happens that merits parent components to recheck statuses.
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();

    public SM_FORM_TYPES = SmFormTypes;

    public inputHasFocus: boolean = false;

    public incomingSyncTimeout: any;
    public outgoingInputTimeout: any;

    private elementChanged$: Subscription;

    constructor(public service: SmFormService,
        public cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.elementChanged$ = this.service.getElementChanged().subscribe(e => {
            if (e.ElementId == this.model.Id ) {
                this.clearIncoming();

                if (this.inputHasFocus) {       
                    this.incomingSyncTimeout = setTimeout(() => {
                        this.updateModel(e);
                        this.incomingSyncTimeout = null;
                    }, 1000);
                } else {
                    this.updateModel(e);
                } 
            }
        });
    }

    ngOnDestroy() {
        this.elementChanged$?.unsubscribe();
    }

    // When a value is changed by an 'input' event,
    // the user will still have focus on the field, so debounce
    // the call so we don't send an API call for every letter typed. 
    public valueChanged(e: FormValueChangeEvent) {
        // If the field is readonly or disabled, don't send any event. 
        if (this.model.Disabled)
            return;

        // Cancel the existing outgoing API call
        this.clearOutgoing();

        // If we have an sync message that came in while the user is still typing,
        // ignore that event as the user is overriding it.
        this.clearIncoming();

        if (e.Type == 'input') {
            // This is called when the value is updated on the 'input' event
            // The user has not left the field, so we don't want to send 
            // the change event immediately. 
            this.outgoingInputTimeout = setTimeout(() => {
                this.clearIncoming();

                this.onValueChanged.emit(e);
                this.outgoingInputTimeout = null;
            }, 1000);     
        } else {
            this.onValueChanged.emit(e);   
        }  
    }

    private clearIncoming() {
        if (this.incomingSyncTimeout) {
            clearTimeout(this.incomingSyncTimeout);
            this.incomingSyncTimeout = null;
        }      
    }

    private clearOutgoing() {
        if (this.outgoingInputTimeout) {
            clearTimeout(this.outgoingInputTimeout);
            this.outgoingInputTimeout = null;
        }   
    }

    public updateModel(e: SmElementChangedEvent) {
        this.model = e.ElementVM as SmFormFieldVM;
        this.cdRef.detectChanges();
        this.onDetectChanges.emit();
    }

    public focusChanged(hasFocus: boolean) {
        this.inputHasFocus = hasFocus;
    }
}

export interface SmFormFieldVM extends SmFormElementVM {
    Required: boolean;
    Value: any;
    PreviousValue?: any;
    FieldType: SmFormTypes; // string, date, etc.
    HelpText?: string;
    Disabled?: boolean; // In edit mode, whether or not the input is disabled.
    IncludeInRepeatable?: boolean;
    Options?: SmFormFieldOptionsVM;
    Errors?: string[];
    FormWidth?: FormWidths;
    Prefix?: string;
    Suffix?: string;
    SpecialType?: 'Currency' | null
}

export interface SmFormFieldOptionsVM  {
    ShowCommas?: boolean;               // Integer, whether to use 1,000,123 or 10001233

    NumberOfDecimals?: number;          // Decimal
    DisplayAsPercentage?: boolean;      // Decimal
    DisplayNegativesInRed?: boolean;    // Decimal & Money
    Separator?: string;                 // Decimal

    Currency?: string;                  // Money (ONLY use if overriding the default currency for the program)
    DisplayAsWholeNumber?: boolean;     // Money

    Options?: string[];                 // MultiSelect (null for not allowing custom), Select or RadioOptions
    AllowSpaces?: boolean;              // MultiSelect

    DateFormat?: SmDateFormats;         // Date

    MultiLineEntry?: boolean;           // String
    MinLength?: number;                 // String
    MaxLength?: number;                 // String
    ButtonAlignment?: string;           // Radio
}

export enum SmFormTypes {
    Boolean = "boolean",
    ContractDate = "contractDate",
    Decimal = "decimal",
    Email = "email",
    Integer = "integer",
    MultiSelect ="multi-select",
    Money = "money",
    Phone = "phone",
    RadioOptions = "radio-options",
    Select = "select",
    String = "string",
    Timestamp = "timestamp",
    Url = "url",
    Year = "year",
    Zip = "zip"
}