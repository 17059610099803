import { Component, Input } from '@angular/core';
import { SmAssignableBadgeVM } from "../sm-assignable-badge/sm-assignable-badge.viewmodel";

@Component({
    selector: 'unity-avatar',
    templateUrl: './unity-avatar.component.html',
    styleUrls: ['./unity-avatar.component.scss']
})
export class UnityAvatarComponent {
    @Input() model: SmAssignableBadgeVM;
    @Input() color: AvatarColor = AvatarColor.Magenta;
    @Input() image: String;
}

export enum AvatarColor {
    Magenta = "magenta",
    Blue = "blue",
    Cyan = "cyan",
    Purple = "purple",
    Ultramarine = "ultramarine",
    Pink = "pink",
};