export const environment = {
    production: true,
    "env": "production",
    "baseUrl": "https://instec-underwriting.com",
    "applicationEndpoint": "https://api.instec-underwriting.com",
    "syncHost": "https://sync.instec-underwriting.com",
    "documentationUrl": "https://api-portal.instec-underwriting.com",
    "access_token":"access_token",
    "applicationInsights": "InstrumentationKey=92e7a367-880b-46ed-b250-d78cfcc27354;IngestionEndpoint=https://eastus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
    "auth": {
        "domain": "instec.auth0.com",
        "clientId": "ZLXIcYv2sG94vFkrmn8bmY4Dv7wCpFqD",
    },
    "qlik": {
        "authUrl": "https://prod1.insuritybi.insurity.com/sure-uw/hub/",
        "reportsUrl": "https://prod1.insuritybi.insurity.com/sure-uw/sense/app/d9320213-20e5-45e9-8ea5-6ffe486ce75b/sheet/681cc4bf-2fea-402e-af05-3e49d7b19ca6"
    },
    "ikcDocumentationUrl": "https://docs.insurity.com/category/sureunderwriting",
    "ikcIntegrationDocumentationUrl": "https://docs.insurity.com/bundle/SureUnderwritingUserHelp/page/webhooks.html",
    "requireAPIM": true
};