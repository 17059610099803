<ng-container *ngFor="let row of model">
    <label class="label_name_grid--label" 
        [ngClass]="{ 
            'label_name_grid--label-form' : row.ForForm,
            'label_name_grid--label-xs': row.LabelSize == 'xs',
            'label_name_grid--label-sm': row.LabelSize == 'sm',
            'label_name_grid--label-md': row.LabelSize == 'md',
            'label_name_grid--label-lg': row.LabelSize == 'lg',
            'label_name_grid--label-xl': row.LabelSize == 'xl'
         }">
        {{ row.Label}}
    </label>
    <div class="label_name_grid--name">
        <div *ngFor="let nameLine of row.InfoLines" class="d-flex label_name_grid--controls">
            <span *ngIf="nameLine.State == 'Normal' || nameLine.State == 'Bold' || nameLine.State == 'Italic'"
                class="label_name_grid--name-text"
                [ngClass]="getLineClasses(nameLine)">
                {{ nameLine.MainText }}
            </span>
            <span *ngIf="nameLine.State == 'GuidReplacement'">
                {{ nameLine.MainText | guidReplacer | async }}
            </span>
            <button unity-button 
                    *ngIf="nameLine.State == 'Link' || nameLine.State == 'Button'" 
                    class="link small" 
                    (click)="nameLine.State == 'Link' ? onLinkClick(row, nameLine) : onButtonClick(row, nameLine)" 
                    [disabled]="nameLine.Disabled">
                {{ nameLine.MainText }}
            </button>
            
            <a *ngIf="nameLine.State == 'Email'" href="mailto:{{nameLine.MainText}}">{{ nameLine.MainText }}</a>
            <a *ngIf="nameLine.State == 'Phone'" href="tel:{{nameLine.MainText}}">{{ nameLine.MainText }}</a>
            <a *ngIf="nameLine.State == 'ExternalLink'" href="{{nameLine.MainText}}" target="_blank">{{ nameLine.MainText }}</a>

            <sm-date-by *ngIf="nameLine.State == 'DateBy'"
                [User]="(nameLine.DeployedBy | async)"
                [Date]="nameLine.DeployedOn"
                [Format]="'Short'">
            </sm-date-by>
            <span *ngIf="nameLine.RightText" class="ms-1 label_name_grid--name-right_text">
                {{ nameLine.RightText }}
            </span>
            <span *ngIf="nameLine.RightIcon" class="ms-1" ngbTooltip="{{nameLine.RightIconTooltip}}" container="body">
                <svg  attr.class="icon-{{nameLine.RightIcon}}">
                    <use attr.xlink:href="#icon-{{nameLine.RightIcon}}"></use>
                </svg>
            </span>
        </div>
    </div>
    <div *ngIf="row.AdditionalColumnText" class="label_name_grid--additional_column">
        {{row.AdditionalColumnText}}
    </div>
</ng-container>
