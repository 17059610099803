import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as _ from 'lodash';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProgramContextCollection } from '@Core/Lib/Contexts/program-context-collection';
import { ProgramChange } from '@Core/CodeGen/Models/configuration.models';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { EditableStateEnum } from '@Core/Lib/Enums/editable-state-enum';

import { UserService } from '@Services/user-service';
import { ProgramProgramsService } from '@Services/program-programs-service';


@Injectable()
export class PatchLockGuard implements CanActivate {
    constructor(public router: Router,
        private userService: UserService,
        private programService: ProgramProgramsService,
        private programContextCollection: ProgramContextCollection) { }

    private programId: string;

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const self = this;

        this.programId = route.parent.params['key'];
        if (!this.programId) {
            console.warn("Tried to run Patch Lock Guard, but the programId was null");
            return of(false);
        }
        var context = this.programContextCollection.get(this.programId);

        var patchId = route.params['patchId'];      
        if (!patchId) {
            this.redirect();
            return of(false);
        }
        context.patchId = patchId;
        context.programId = this.programId;
        context.changeId = null;
        
        return combineLatest([
            this.userService.User$,
            // this.programService.patch(changeId, context)
        ]).pipe(map(
            responses => {
                //TODO: Actual Patch locking to be addressed in SM-7748. 
                // This code path ensures the EditableState gets set to patch on page refresh

                self.programService.setEditableState({ EditableState: EditableStateEnum.Patch });
                return true;
            }, error => {
                self.redirect();
                return false;
            }
        ));
    }

    private redirect() {
        this.router.navigate(['Conf', 'Programs', this.programId]);
    }
}
